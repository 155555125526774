.notFound {
  background-color: #181511;
  font-family: "Spartan", san-serif;
  min-height: 100vh;
  min-width: 100vw;
  padding-top: 15%;
}
.imageCenter {
  display: flex-row;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .notFound {
    padding-top: 50%;
  }
}
