.footer {
  clear: both;
  background-color: transparent;
  width: 100%;
  text-align: center;
  padding-bottom: 5px;
  color: #bd9a75;
}

.footer a {
  color: #bd9a75;
  text-decoration: none;
}

.footer a:hover {
  color: #b2a2b9;
  text-decoration: underline;
}

/* .separator {
  border-top: 1px dashed #bcafb4;
} */

@media screen and (min-width: 320px) and (max-width: 767px) {
  .footer {
    padding-bottom: 15px;
  }
}
