.navBackgroundTransparent {
  background-color: transparent;
  padding: 8;
}

.navBackgroundColor {
  background-color: #181511;
  filter: drop-shadow(-5px -5px 5px #f1f2f3);

  padding: 8;
}

.navbar-brand {
  margin-left: 10px;
}

.show,
.collapsing {
  background-color: #181511;
}
