.contact {
  padding-top: 75px;
  margin-bottom: 26px;
}

.formCol {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactCTA {
  padding: 10px 0px;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .contact {
    padding-bottom: 10px;
  }
}
