/*
 #181511 #e5ddc5 #bd9a75 #f1f2f3
 */

.viewPage {
	background-color: #181511;
	font-family: 'Spartan', san-serif;
	color: #f1f2f3;
}

.pageContent {
	margin-top: 40px;
	margin-bottom: 50px;
	min-height: 85vh;
}

a {
	/* grows all links on hover */
	transition: all 0.2s ease-in-out;
}
a:hover {
	transform: scale(1.1);
}

.image-container,
.image-container-glow {
	display: flex;
	justify-content: center;
	align-items: center;
}
.image-container-glow {
	filter: drop-shadow(0px 0px 10px #f1f2f3);
}

.section-container {
	padding: 20px 0px;
	margin: 20px 0px;
}

.btn-custom {
	background-color: #bd9a75 !important;
	border-color: #bd9a75 !important;
}

/* mobile screens */
@media screen and (min-width: 320px) and (max-width: 767px) {
	.section-container {
		padding-bottom: 10px;
	}
	.pageContent {
		margin-top: 100px;
	}
	.logo-image {
		padding-top: 40px;
		width: 50%;
	}
}
